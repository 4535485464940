import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Post } from "../components/Core";
import DataTable from '../components/data/neobanque/neobanque'
import DataTableMobile from '../components/data/neobanque/neobanqueMobile'
import DataTableFraisBancaire from '../components/data/neobanque/neobanqueFraisBancaire'
import DataTableFraisBancaireMobile from '../components/data/neobanque/neobanqueFraisBancaireMobile'
import DataTableAvantage from '../components/data/neobanque/neobanqueAvantage'
import DataTableAvantageMobile from '../components/data/neobanque/neobanqueAvantageMobile'
import Disclosure from '../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import { faBuilding} from '@fortawesome/free-regular-svg-icons'

function Accounting(props) {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Meilleure néobanque - comparatif détaillé des offres de BankBank</title>
                <meta name="description" content="Trouvez la meilleure néobanque en comparant leurs spécialités, tarifs et cartes bancaires. À vous la carte metal au meilleur prix." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Néobanque</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}><span>Néobanque</span> - Classement 2021</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure néobanque avec un comparatif tarifs et cartes bancaires (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur néobanque</span> pour déterminer vos priorités : promo, carte gratuite, frais</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponible dès aujourd’hui</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure néobanque</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5, width: '100%', display: 'inlineBlock'}}>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faChartBar} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Classement Complet </Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Frais Bancaires</Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faCreditCard} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Avantages et Services</Tab>

    </TabList>

    <TabPanel>
      <DataTable id={[35,15,2,16,36,18,30,9,38]}/>
      <DataTableMobile id={[35,15,2,16,36,18,30,9,38]}/>
    </TabPanel>
      <TabPanel>      
	  <DataTableFraisBancaire id={[35,15,2,16,36,18,30,9,38]}/>
      <DataTableFraisBancaireMobile id={[35,15,2,16,36,18,30,9,38]}/>
    </TabPanel>
      <TabPanel>    
	  <DataTableAvantage id={[35,15,2,16,36,18,30,9,38]}/>
      <DataTableAvantageMobile id={[35,15,2,16,36,18,30,9,38]}/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
              {/* <Disclosure/> */}
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
          <Post>
    
      
		  <h2>Comment choisir la meilleure néobanque ?</h2>
<p>Vous avez peut-être été accroché par quelques articles annonçant la “révolution fintech” et l’apparition des “néobanques”. En effet, le paysage bancaire semble ne plus devoir s’arrêter de se transformer dans les années qui viennent.  Et c’est une très bonne nouvelle!
</p>
<StaticImage
		  	src='../images/neobanque.png' width={1200}
			alt='meilleure neobanque'
			placeholder='tracedSVG'
			/>


<p>Au coeur de cette révolution, ce sont les nouvelles attentes des clients qui sont posées en priorité.</p>

<h3>Une néobanque, c’est quoi ?</h3>
<p>
Le compte néobanque c’est quoi ? Difficile de trouver une définition claire et harmonisée du terme tant il est usité par tous dans des cas bien différents. Tentons d’en trouver l’essence avant d’aller dans le détail. Ce que nous promettent les néobanques c’est une simplicité poussée au max. Elles proposent aussi une instantanéité presque totale qui devrait couvrir à terme tous les services des banques traditionnelles.
</p><p>
La néobanque c’est d’abord la version minimaliste, digitale et mobile d’une banque :
</p>
<ul>
	<li>Un accès simplifié et immédiat à un moyen de paiement et de gestion de paiement</li>
	<li>Toutes les opérations gérées depuis l’application smartphone (et non depuis l’accès au site comme beaucoup de banques en ligne)</li>
	<li>Une carte à débit immédiat et contrôle de solde qui ne peut pas aller dans le rouge</li>
	<li>L’essentiel, rien que l’essentiel : payer et recevoir des paiements.C’est souvent (mais pas toujours) une carte immédiate sans besoin de créer un compte, comme une carte SIM prépayée</li>
</ul>

<h3>Néobanque = Banque digitale = Banque mobile</h3>
<p>
Conçues pour être utilisées sur un smartphone avant tout, voici la catégorie où l’on retrouve les néo-banques. On les appelle aussi Banques Mobiles car elles fonctionnent via une application mobile principalement et leurs services s’articulent autour d’une simple carte bancaire. La gratuité n’est pas totale mais souvent les frais sont moins élevés qu’avec une banque traditionnelle.
</p><p>
Les néobanques sont encore là pour compléter plus que pour remplacer :
</p><p>
Le premier réflexe de BankBank, pour vous faire gagner de l’argent, c’est de comparer les tarifs. Les néobanques rivalisent aisément avec les réseaux bancaires traditionnels, qui offrent toutefois une gamme de services bien plus large. Par rapport aux banques en lignes par contre, les néobanques ne sont pas moins chères. Elles luttent notamment avec les offres “tout gratuit” lancées il y’a quelques années.
</p><p>
Le second point critique pour nous c’est sûrement les services proposés. Les banques en ligne sont pour l’instant bien plus équipées en terme d’offre et moins chères sur de nombreuses options.
</p>
<p>
Exemple :</p>

<ul>
	<li>gratuité des retraits dans quasiment tous les distributeurs</li>
	<li>cartes haut de gamme à des prix imbattables</li>
	<li>accès aux solutions d’épargne et à des chéquiers…).</li>
</ul>
<p>
Les néobanques visent donc plus souvent à être des alternatives complémentaires à des comptes bancaires traditionnels (avoir deux à trois comptes bancaires est de plus en plus courant aujourd’hui). Elles permettent de gérer des achats du quotidien, un voyage, ou une une situation plus compliquée financièrement ou personnellement…
</p><p>
La multibancarisation est en marche selon le fondateur du compte Nickel, Hugues Le Bret. La seconde étape consistera à l’ouverture de toutes les options d’épargne,  et d’assurance que proposent les opérateurs traditionnels.
</p>
<h3>Histoire de la néobanque – le séisme qui secoue le marché européen et mondial</h3>
<p>
Elles sont apparues aux Etats-Unis après la crise de 2009.  Cette période délicate a facilité l’ouverture légale des conditions de création de comptes courants pour les particuliers.
</p>
<ul>
	<li>Simple lancée en 2012</li>
	<li>Moven en 2011</li>
	<li>Varo Money</li>
	<li>BankMobile en 2015</li>
</ul>

<p>Elles se sont ensuite diffusées au Royaume-Uni.</p>

<ul>
	<li>Atom Bank en 2016</li>
	<li>Tandem en 2016</li>
	<li>Revolut en 2016</li>
	<li>Monzo fin 2015)</li>
</ul>

<p>
Et en Allemagne avec Fidor en 2015 et N26 ancien Number 26 en 2015).</p>
<p>
Enfin la vague a atteint le reste de l’Europe avec des pionniers novateurs en France : Le Compte Nickel en 2013, Soon d’Axa Banque et Freazy pour les 18-30 ans en 2014 (qui se placent entre la néobanque et la banque en ligne), C’Zam en 2016, Orange Bank qui débarque fin 2017.
</p><p>
Et bien d’autres à venir…
</p>
<h3>
La néobanque en Europe</h3>
<p>
Née en janvier 2015 sous le nom Numero26, N26 avait annoncé début 2016 avoir dépassé le seuil des 100 000 clients sur toute l’Europe. Un peu plus d’un an plus tard, la banque mobile allemande a franchi le cap des 300 000 utilisateurs. 300 000 utilisateurs c’est 1 000 nouveaux clients par jour !
</p><p>
N26 s’est concentrée sur la rapidité de création d’un compte. L’ouverture prend moins de 8 minutes. On est loin des heures passées à scanner ses documents puis signer des pages de contrat pour les banques traditionnelles ou même digitales.
</p><p>
Comment font-ils ? Un système de visioconférence en quelques minutes pour vérifier votre passeport et votre identité. N26 s’est concentré sur une clientèle plutôt business. La néobanque offre des taux de change transparent et automatiques ainsi que des tarifs de transfert interbancaires sur toute l’Europe imbattables.
</p><p>
Autre particularité de N26 : sa dimension européenne. Lancée à l’origine seulement en Allemagne et en Autriche, l’enseigne basée à Berlin a profité de l’obtention de sa licence bancaire pour s’étendre très rapidement à l’international. Elle couvre désormais toute la zone Euro. Le modèle économique de N26 repose sur les intérêts perçus en cas de découverts sur les comptes. N26 partage aussi des frais générés par certains services partenaires comme TransferWise pour l’échange de devises.
</p>
<h3>La première néobanque anglaise</h3>
<p>
Atom Bank, a été créée en avril 2016 et s’est vite démarquée en intégrant un panel de nouvelles technologies. Elle permet une utilisation complètement différente et une facilité d’accès jusque là jamais vue dans le secteur des comptes bancaires.
</p>
<ul>
	<li>L’authentification biométrique (pour l’instant, à l’instar des nouveaux Iphones, reconnaissance d’empreinte digitale)</li>
	<li>L’analyse de données créées par les clients pour prévoir les comportements</li>
	<li>Assistance intelligente au service des clients</li>
	<li>Chatbot intelligent qui répond à quasiment toutes les question</li>
</ul>

<p>Cela impressionne, fait parfois peur, mais révèle bien l’ambiance : tout va très vite et le client est au coeur de l’attention. La banque promet par exemple de fournir ses services 24h/24 en continu. Elle compte aujourd’hui plus de 100 000 utilisateurs alors que la marque reste discrète sur son évolution.
</p><p>
Même focus business et international pour la néobanque Revolut. Son application au design novateur vous permet de transférer de l’argent dans n’importe quelle devise sans avoir à payer de frais. Il est aussi possible de transférer de l’argent entre deux utilisateurs toujours sans frais via SMS, email. Sa carte bancaire vous permet de faire des achats dans n’importe quel pays sans avoir de coût supplémentaire. C’est le véritable zéro frais de change. Déjà plus de 700 000 clients en Europe, dont un peu plus de la moitié au Royaume Uni, Revolut est partie pour laisser sa trace dans le marché des comptes bancaires en Europe.
</p><p>
Dans la foulée, Monzo, anciennement Mondo, progresse encore plus vite au Royaume-Uni depuis sa création en février 2015. Elle propose des applications similaires et un marketing poussé sur l’appartenance à une communauté d’utilisateurs jeunes et créatifs. Elle rassemble notamment ses clients autour d’événements organisés à Londres.
</p>
<h3>
La néobanque en France</h3>
<p>
En France, le Compte Nickel a été un précurseur : en deux ans, plus de 600 000 français ont adopté cette formule inspirée par les pionniers européens en la matière. Le compte en ligne simplifié Soon d’Axa Banque et Freazy du Crédit Agricole pour les 18-30 ans ont été lancés en réaction à cette révolution dès 2014. Dans la lignée, Carrefour propose depuis le 18 avril 2017 sa formule C’Zam disponible dans 3 000 points de vente Carrefour. Enfin, Orange a annoncé le 17 juillet le lancement officiel de Orange Bank qui devrait s’aligner avec les offres des deux précédents, en version gratuite.
</p><p>
La plupart de ces nouvelles banques se rémunèrent grâce aux commissions d’interchange et surtout sur les découverts. Mais d’autres, qui ne possèdent pas l’agrément bancaire et ne peuvent pas proposer de prêts, misent sur des services plus personnalisés. C’est le cas de Revolut dont le cœur de métier repose sur les paiements à l’international. Les nouveaux entrants plébiscitent la stratégie de « hub fintech », qui consiste à assembler et proposer les meilleurs services de start-up fintech pour proposer aux clients une banque comme service “Banking as a Service”.
</p><p>
Les néobanques présentent évidemment des avantages comme des inconvénients. BankBank mettra donc à votre disposition en temps réel toute l’information disponible sur ces comptes bancaires qui séduisent de plus en plus de français.
</p><p>
L’intérêt des fonds d’investissements et des banques qui craignent pour leur plates-bandes fait désormais exploser ce secteur à coup de montées d’argent massives. Le futur est en marche, et c’est tout bon pour nous.
</p>

<h2>Comparer les meilleures Néobanques</h2>
<h3>Tarifs des Néobanques</h3>
<p>
Orange bank impose un minimum de retraits et d’actions par mois. Un client doit effectuer au moins 3 paiements ou retraits par mois. A défaut, des frais de tenue de compte, à hauteur de 5€ par mois, seront perçus).
</p><p>
Les autres néobanques ont un coût relativement bas qui dépendra sensiblement de l’utilisation. Pour exemple, en usage moyen, le Compte Nickel estime ses frais bancaires à 54 € par an. Ces frais peuvent varier 20€ et 60€ maximum par an. C-zam a opté pour une autre formule de facturation . L’achat du coffret coûte 5€ et contient une carte. L’abonnement est par la suite seuelement 1 euro par mois. Soit 17€ la première année et 12€ les suivantes. Pour ce prix, toutefois, pas de conditions spéciales à remplir. Les néobanques acceptent tous les clients majeurs, y compris les interdits bancaires.
</p><p>
Les avantages des néobanques pour un type particulier d’utilisateurs ?
</p>
<h3>Une banque sans liquide</h3>
<p>
Les néobanques c’est surtout pour les gens qui utilisent peu de liquide : Si l’utilisation de l’argent liquide reste en vogue, il faut savoir que les retraits sont généralement payants.
</p><p>
Pour le compte C’Zam, les retraits sont gratuits uniquement dans les distributeurs Carrefour et BNP Paribas, sinon ils sont facturés 1€ par retrait dans la zone Euro.
</p><p>
Pour le compte Nickel, un retrait chez n’importe quel distributeur est tarifé à 1€, et un retrait chez un buraliste associé coûte 0,50€. Quant à la Orange Bank, les tarifs n’ont pas encore été dévoilés, Bankbank vous tiendra au courant ;)!
</p>
<h3>Une banque sans chèque</h3>
<p>
La néobanque, c’est dire adieu aux chèques : terminé les vieilles méthodes de paiement déjà quasiment abandonné par les français depuis quelques années (moins de 6% d’usage par les français selon un sondage IPSOS réalisé en 2016), la carte de paiement qui est associée avec le compte ouvert est à la base de toutes vos transactions.
</p><p>
La néobanque, c’est un support sur mobile, plus encore que les banques digitales, tout se fait principalement depuis sur l’application mobile. Une autre manière de faire, servie par des applications claires qui permettent un suivi interactif des dépenses. ces applications ont vocation à évoluer pour devenir des assistants de dépense, vous permettant de suivre votre budget précisément et de manière interactive.
</p>
<p>
Ce sont aussi plafonds assez bas pour des budgets modérés :</p>
<p>
Par défaut, le compte Nickel impose un plafonnement des retraits à 300€ sur 7 jours glissants et une limite des paiements à 1500€ sur 30 jours (ces limites correspondent au minimum et peuvent être augmentées en confirmant son e-mail et en utilisant le compte pendant un mois.
	</p>
	<p>
Le compte C’Zam a par défaut un plafonnement plus généreux :</p><p>
Les plafonds fixés pour les opérations de retrait et de paiement avec la carte C-zam sont les suivants : le plafond de retrait standard est de 500€ sur 7 jours glissants tandis que le plafond de paiement standard est de 3000€ sur 30 jours glissants.
</p><p>
Le compte Soon est gratuit à partir 900€ d’achats en carte bancaire par trimestre civil tandis que pour les clients âgés de moins de 30 ans ce seuil d’achats par carte est fixé à 450€.
</p><p>
En revanche, pas d’obligation d’achat pour le compte Freazy 12-30 ans.
</p>
<h3>
Une banque pour les globe-trotteurs</h3>
<p>
Pour les globe-trotteurs qui s’aventurent en dehors de la zone SEPA</p><p>
Un des avantages des cartes des néobanques, c’est la modération des tarifs des retraits et paiements effectués hors zone SEPA. C-zam est favorisé : Carrefour Banque ne facture qu’1€ pour ces retraits en devises – contre 2€ chez Compte Nickel – et ne perçoit aucune commission sur les paiements en devises, quand Compte Nickel les facture 1€ pièce.
</p><p>
Des produits qui reviennent à l’essentiel: faire des paiements et dépenser l’argent que l’on a vraiment, tout de suite et sans prise de tête.
</p><p>
En effet, seules les cartes à autorisation systématique sont proposées (avec contrôle si le solde de votre carte est positif comme les cartes Electron de Visa ou Maestro chez mastercard, déjà offertes par les banques traditionnelles comme alternative visant les plus jeunes ou les comptes en banque moins fournis). Si le solde n’est pas positif, le paiement sera alors refusé. Ce n’est pas le cas du compte Soon qui propose une carte Mastercard internationale à débit immédiat sans contrôle du solde (pas de sms lors d’une action). Aucun découvert n’est possible cependant puisque ce n’est pas une carte de crédit.
</p>

<h3>
Le problème des prélèvements</h3>
<p>
En cas de prélèvement sur le compte qui dépasserait le solde disponible, le 3e rejet du mois activera une charge de 5€ par refus (dans la limite de 20€ par mois) pour le Compte Nickel. Attention encore une fois : le tarif facial du compte C’Zam est effectivement moins élevé ( 12€ contre 20€ par mois pour Le Compte Nickel et gratuit pour la Orange Bank), mais en contrepartie, les charges de refus de prélèvement chez l’offre de Carrefour s’élèvent à 20€ par refus de prélèvement SEPA après le 5e refus.
</p><p>
On ne cesse de le répéter chez BankBank, il n’y a pas de meilleure offre mais il y’a UNE meilleure offre pour chaque type d’utilisateur : se connaître c’est économiser!
</p><p>
Une porte vers les services technologiques de pointe :
</p><p>
Les néobanques représentent une porte vers une flopée de nouvelles technologies et de services. Toutes les néo banques s’accordent pour être des fers de lance des nouveaux services pour les utilisateurs : paiement par mobile, Apple Pay, blocage de carte à volonté, gestion assistée du solde, applications de plus en plus performantes, assistance avec intelligence artificielle sont les promesses des néobanques pour les mois et années à venir.
</p>
<h3>
Une banque avec carte bancaire immédiate</h3><p>
Les comptes Nickel, C-zam et bientôt Orange Bank permettent de bénéficier immédiatement d’une carte bancaire opérationnelle. Aucune banque, traditionnelle ou en ligne n’est capable de faire aussi bien, même si toutes travaillent à raccourcir et faciliter l’ouverture du compte, le délai pour obtenir la carte bancaire prend d’1 à 5 semaines, selon la banque. Cela n’est pas le cas des comptes Soon et Freazy qui nécessitent d’envoyer un scan ou une photographie de vos pièces d’identité et justificatifs de domicile avant de vous envoyer la carte par mail (estimer 4 à 5 jours de délai en moyenne pour recevoir la carte).
</p>
<h3>
Une banque avec service client automatisé</h3><p>
Du côté service client, on retrouve le standard des banques en ligne avec un Forum et un Question/Réponse pour chaque néobanque qui sont particulièrement complets. A défaut de trouver une solution à travers ces services, une hotline est arrangée pour chaque banque, 24/7 et le plus souvent gratuite pour les clients. La simplicité du compte permet surtout d’échapper aux situations complexes et les questions face à une incompréhension sur les tarifs, les charges ou autres.
</p>
<h3>
Compte en temps réel</h3><p>
C-zam, comme le Compte Nickel et N26, sont capables de répercuter quasi-immédiatement sur le solde le montant des achats et retraits effectués par carte. A travers un sms, un email ou un notification dans l’application, vous recevrez un signal de votre achat ou paiement avec ses caractéristiques et son prix. De la même manière lorsque vous recevrez un paiement par transfert ou dépôt sur votre compte.
</p>
<h3>
Option de blocage et déblocage de la carte</h3>
<p>
Le comptes Nickel et Orange Bank vous offrent la possibilité de verrouiller temporairement de façon gratuite et illimité votre carte mastercard si vous pensez l’avoir perdue ou égarée. Si vous la retrouvez, vous pouvez la réactiver en un clic et l’utiliser immédiatement. Si, dans le cas contraire, vous ne la retrouvez pas, vous pouvez faire opposition définitivement et procéder au remplacement de votre carte. Cette option n’est pas offerte pour l’offre C’Zam, ni pour Freazy et Soon.
</p>
<p>
Une carte contactless :</p>
<p>
Les comptes Orange et C’Zam on une carte Contactless qui vous permettent de payer en touchant simplement le terminal de paiement avec votre carte. Cela n’est pas le cas du compte Nickel, ni pour Freazy et Soon.
</p>
<h2>
Comment ouvrir un compte néobanque ?</h2>
<p>
La néobanque c’est un compte opérationnel en quelques minutes sans besoin de montrer patte blanche; pas de minimum de ressource, pas besoin non plus de justificatifs de domicile ou d’identité contrairement aux banques digitales : aussi simple qu’une carte SIM prépayée.
</p><p>
Avec des particularités : les comptes de la toute nouvelle Orange Bank seront disponibles dans les 180 premières agences spécialisées dans toute la France (et plus à venir).
</p><p>
Les comptes Nickel, eux, s’ouvrent chez plus de 2 687 buralistes partenaires dans toute la France. Il n’autorise un chargement de la carte immédiat qu’en espèces et au comptoir du buraliste en moyennant une ponction de 2% de la somme déposée (qui rémunérera le buraliste qui offre ce service).
</p><p>
Enfin les comptes C’Zam sont trouvables dans tous les magasins et supermarchés Carrefour (un vaste réseau dans toute la France). Le compte peut même être alimenté immédiatement, à condition de le charger avec une autre carte bancaire .
</p>
<h2>Accrochez vos ceintures, ce n’est que le début!</h2>
<p>
Le séisme qui secoue le marché des banques vient à peine de commencer, et va laisser la porte ouverte à une vague d’innovations et de nouveaux venus qui vont changer les règles du jeu. C’est l’occasion de profiter d’une époque où le client de la banque reprend le pouvoir. Mais ce secteur devient aussi de plus en plus nébuleux et même les spécialistes ne savent plus où donner de la tête actuellement. BankBank aura pour mission d’accompagner ces changements pour rendre toute cette information limpide à tous nos lecteurs.
</p><p>
La fintech a été l’objet d’une prise de participation majoritaire au capital (79%) de la banque Edel (filiale du groupe de distribution E. Leclerc). Elle a récemment lancé le compte courant « Morning pay », avec un IBAN et une carte MasterCard à 2 €/mois avec 2 retraits aux distributeurs en zone SEPA, puis 0,90€ au-delà. Ce dernier permettra par ailleurs d’utiliser les outils historiques de l’établissement : le cagnottage et le transfert direct d’argent entre clients.
</p><p>
Autre concurrent sur la ligne de départ : Anytime est un service 100% en ligne offrant une carte de paiement prépayée et facturée 27€ par an. La carte bancaire est remise sans conditions de ressources mais aussi sans découvert autorisé. Autre chose à savoir aussi : les retraits en liquide seront facturés 2€ dans n’importe quel distributeur en Europe et en France.
</p>

</Post>

          </Container>
          <Footer/>
      </PageWrapper>
    )
}

export default Accounting
