import React from 'react'
import { ColumnFilter } from '../ColumnFilter'


export const COLUMNS = [
  // {
  //   Header: 'Néobanque',
  //   accessor: 'BanqueLogo',
  //   Filter: ColumnFilter,
  //   sticky: 'left',
  //   width: 180,
  //   // width: 50,
  //   Cell: e =>{
  //     let val = e.value.split(',')
      
  //     return (

  //      <div style={{marginTop: 15}}>
  //        <a href={`/go/?go=${val[0]}&url=${val[2]}`}
  //        state={{url: val[1]}}
  //        target='_blank' rel="noreferrer">
  //        <img style={{width: 150}} src={`/logos/${val[0].replace(' ', '-').replace('.', '').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}.png`} alt={`${e.value}`}></img>
  //      </a>

  //        </div>
  //     )
  //   },
  // },
 
  // {
  //   Header: 'Carte bancaire',
  //   accessor: 'BanqueCarte',
  //   Filter: ColumnFilter,
  //   sticky: 'left',
  //   width: 180,
  //   // width: 50,
  //   Cell: e =>{
  //     let val = e.value.split(',')
      
  //     return (

  //      <div style={{marginTop: -5}}>
  //        <a href={`/go/?go=${val[0]}&url=${val[2]}`}
  //        state={{url: val[1]}}
  //        target='_blank' rel="noreferrer">
  //        <img style={{width: 130}} src={`/cartes/carte-bancaire-${val[0].replace(' ', '-').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}-${val[1].replace(' ', '-').replace(' ', '-').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}.png`} alt={`${e.value}`}></img>
  //      </a>

  //        </div>
  //     )
  //   },
  // },



  {
  
    Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Assurance</div><div style={{marginTop: -5}}>médicale</div></div>),
    accessor: "AssuranceMedicale",
    Filter: ColumnFilter,
    width: 180,
    padding: 27,
    Cell : e => {
  
      if(e.value === 'illimité'){
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
            <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
          </div> 
        )
        }
      else {
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
          <div style={{fontSize: 24}}>{e.value}</div>
          {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div> */}
        </div>
        )
      }
  }},

  {
  
    Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Assurance</div><div style={{marginTop: -5}}>bagages</div></div>),
    accessor: "AssuranceBagages",
    Filter: ColumnFilter,
    width: 180,
    padding: 27,
    Cell : e => {
  
      if(e.value === 'illimité'){
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
            <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
          </div> 
        )
        }
      else {
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
          <div style={{fontSize: 24}}>{e.value}</div>
          {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div> */}
        </div>
        )
      }
  }},


  // {
  
  //   Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Support client</div><div style={{marginTop: -5}}>prioritaire</div></div>),
  //   accessor: "SupportClientPrioritaire",
  //   Filter: ColumnFilter,
  //   width: 150,
  //   padding: 27,
  //   Cell : e => {
  
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //       }
  //     else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div> */}
  //       </div>
  //       )
  //     }
  // }},

  // {
  
  //   Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Crypto</div><div style={{marginTop: -5}}>Monnaie</div></div>),
  //   accessor: "CyrptoMonnaie",
  //   Filter: ColumnFilter,
  //   width: 140,
  //   padding: 27,
  //   Cell : e => {
  
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //       }
  //     else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div> */}
  //       </div>
  //       )
  //     }
  // }},

  // {
  
  //   Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Livraison</div><div style={{marginTop: -5}}>Express</div></div>),
  //   accessor: "LivraisonExpress",
  //   Filter: ColumnFilter,
  //   width: 140,
  //   padding: 27,
  //   Cell : e => {
  
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //       }
  //     else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div> */}
  //       </div>
  //       )
  //     }
  // }},

  // {
  
  //   Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Cashback</div><div style={{marginTop: -5}}>Europe</div></div>),
  //   accessor: "CashbackEurope",
  //   Filter: ColumnFilter,
  //   width: 150,
  //   padding: 27,
  //   Cell : e => {
  
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //       }
  //     else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24}}>{e.value}</div>
  //         {/* <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div> */}
  //       </div>
  //       )
  //     }
  // }},

  
  
//   {
//     Header: 'En savoir +',
//     accessor: 'BanqueWebsite',
//     width: 175,
//     padding: 18,
//     Cell: e =>{
//       let val = e.value.split(',')

//       return (
//         // <span>{e.value}</span>
//         <div style={{padding: 6}}>

// <a href={`/go/?go=${val[0]}&url=${val[2]}`}
//          state={{url: val[1]}}
//          target='_blank' rel="noreferrer">
//                 <BS.Button id="comparateurButton" type="submit" className="btn-lg" style={{height: 40, backgroundColor: '#E5234F', borderColor: '#E5234F', width: 120, padding: 7, fontSize: 14, boxShadow: '0 0 8px 0 rgb(0 0 0 / 30%)'}}>+ d'infos <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5}}/></BS.Button>
//           </a>
//         </div>
//       )
//     },
//   },
]