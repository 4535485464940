import React from 'react'
import { ColumnFilter } from '../ColumnFilter'
import * as Bs from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

export const COLUMNS = [
  // {
  //   Header: 'Néobanque',
  //   accessor: 'BanqueLogo',
  //   Filter: ColumnFilter,
  //   sticky: 'left',
  //   width: 170,
  //   // width: 50,
  //   Cell: e =>{
  //     let val = e.value.split(',')
      
  //     return (

  //      <div style={{marginTop: 15}}>
  //        <a href={`/go/?go=${val[0]}&url=${val[2]}`}
  //        state={{url: val[1]}}
  //        target='_blank' rel="noreferrer">
  //        <img style={{width: 150}} src={`/logos/${val[0].replace(' ', '-').replace('.', '').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}.png`} alt={`${e.value}`}></img>
  //      </a>

  //        </div>
  //     )
  //   },
  // },
 
  {
    Header: 'Carte bancaire',
    accessor: 'BanqueCarte',
    Filter: ColumnFilter,
    sticky: 'left',
    width: 200,
    // width: 50,
    Cell: e =>{
      let val = e.value.split(',')
      
      return (

       <div style={{marginTop: -5}}>
         <a href={`/go/?go=${val[0]}&url=${val[2]}`}
         state={{url: val[1]}}
         target='_blank' rel="noreferrer">
         <img style={{width: 130}} src={`/cartes/carte-bancaire-${val[0].replace(' ', '-').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}-${val[1].replace(' ', '-').replace(' ', '-').replace('é', 'e').replace('é', 'e').replace('é', 'e').replace('é', 'e').toLowerCase()}.jpg`} alt={`${e.value}`}></img>
       </a>

         </div>
      )
    },
  },

  
  


  // {
  //   Header: 'Coût mensuel',
  //   accessor: 'Tarif',
  //   Filter: ColumnFilter,
  //   width: 130,
  //   padding: 27,
  //   Cell : e => {
      
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //     } else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24, marginTop: 10}}>{e.value}</div>
  //         <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>par mois</div>
  //       </div>
  //       )
  //     }
  // }},


  // {
  
  //   Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Transfert 150 devises</div><div style={{marginTop: -5}}>taux interbancaire</div></div>),
  //   accessor: "Transferts150DevisesTauxInterbancaire",
  //   Filter: ColumnFilter,
  //   width: 200,
  //   padding: 27,
  //   Cell : e => {
  
  //     if(e.value === 'illimité'){
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //           <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
  //         </div> 
  //       )
  //     } else if (e.value === 1) {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24}}>{e.value}</div>
  //         <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateur</div>
  //       </div>
  //       )
  //     }
  //     else {
  //       return (
  //         <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
  //         <div style={{fontSize: 24}}>{e.value}</div>
  //         <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div>
  //       </div>
  //       )
  //     }

  // }},
  
  {
    Header: 'En savoir +',
    accessor: 'BanqueWebsite',
    width: 200,
    padding: 18,
    Cell: e =>{
      let val = e.value.split(',')

      return (
        // <span>{e.value}</span>
        <div style={{padding: 6}}>

<a href={`/go/?go=${val[0]}&url=${val[2]}`}
         state={{url: val[1]}}
         target='_blank' rel="noreferrer">
                <Bs.Button id="comparateurButton" type="submit" className="btn-lg" style={{height: 40, backgroundColor: '#E5234F', borderColor: '#E5234F', width: 120, padding: 7, fontSize: 14, boxShadow: '0 0 8px 0 rgb(0 0 0 / 30%)'}}>+ d'infos <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5}}/></Bs.Button>
          </a>
        </div>
      )
    },
  },
]