import React, {useMemo} from 'react'
import { useTable, useSortBy, useBlockLayout} from 'react-table'
import { useSticky } from 'react-table-sticky'
import theData from './neobanque.json'

import { COLUMNS } from './neobanque-columns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faSort} from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap-grid.css"
import * as Bs from "react-bootstrap"
import './table.css'
import { Styles } from './TableStyles'




function Banque(props) {

let idArray = []

if(props.id){
  idArray = props.id
}

console.log('idArray', idArray)


  function checkFilter(line){
    return line.Active === 'yes'
    // && idArray.includes(line.ID)
  }
  console.log('the Data', theData.filter(checkFilter))

  const newData = theData.filter(checkFilter)
  
  const columns = useMemo(()=> COLUMNS,[])
  const dataQonto = useMemo(() => newData, [])


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns: columns,
    data: dataQonto,
    initialState: {
      sortBy: [
          {
              id: 'Tarif',
              desc: false
          }
      ]
  }
  }, 
  useSortBy,
  useBlockLayout,
  useSticky)

  

  return (
    <>


    <Bs.Container style={{marginTop: 20, maxWidth: 1280, marginLeft: -10, marginRight: -10}}>

<Styles id='dataTableDesktop'>
      <div {...getTableProps()} className="table sticky" >
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div 
                {...column.getHeaderProps(column.getSortByToggleProps())}
                
                {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  <span style={{cursor: 'pointer'}}>
                  {column.isSorted ? (column.isSortedDesc ?  <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10}}/>: <FontAwesomeIcon icon={faSortUp} style={{marginLeft: 10}}/>): <FontAwesomeIcon icon={faSort} style={{marginLeft: 10}}/> }
                </span>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body" style={{fontSize: 18, color: 'black'}}>
        
          {rows.map((row) => {
            // if(row.cells[0].value.split(',')[0] === 'Qonto'){

              // console.log('row value', row.getRowProps())
            // }
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr" 
              >
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps()} className="td">
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        
      </div>
    </Styles>
    </Bs.Container>
    </>
  )
}

export default Banque
